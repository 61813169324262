@font-face {
  font-family: "ModelicaRegular";
  src: url("../fonts/BwModelica-Regular.otf") format("opentype");
}

@font-face {
  font-family: "ModelicaBold";
  src: url("../fonts/BwModelica-Black.otf") format("opentype");
}

@font-face {
  font-family: "ModelicaLight";
  src: url("../fonts/BwModelica-Light.otf") format("opentype");
}

@font-face {
  font-family: "ModelicaItalic";
  src: url("../fonts/BwModelica-RegularItalic.otf") format("opentype");
}

@font-face {
  font-family: "RedwoodHollow";
  src: url("../fonts/Redwood-Hollow.otf") format("opentype");
}

* {
  box-sizing: border-box;
  color: rgb(116, 124, 111);
  font-family: "ModelicaLight";
}

body {
  max-width: 100vw;
}

.font-regular {
  font-family: "ModelicaRegular";
}

.font-bold {
  font-family: "ModelicaBold";
}

.font-light {
  font-family: "ModelicaLight";
}

.font-italic {
  font-family: "ModelicaItalic";
}

.redwood-hollow {
  font-family: "RedwoodHollow";
}

.spaced {
  letter-spacing: 5px;
}

#root {
  background-color: rgb(247, 243, 232);
}

ul.indicators {
  display: none !important;
}

.main-div {
  background-image: url(../images/main_background.webp);
  background-size: cover;
  background-position-x: center;
  min-height: 100vh;
}

.main-div2 {
  min-height: 100vh;
}

.footer {
  background-color: rgb(116, 124, 111);
}

.icon path {
  color: white;
  fill: white;
}

footer * {
  color: white;
}

.extra {
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my-border {
  border-top: 1px solid rgb(116, 124, 111);
}

.border-green {
  border-color: rgb(116, 124, 111) !important;
}

.slide-image {
  outline: 1px solid white;
  outline-offset: -10px;
}

.bg-green {
  background-color: rgb(116, 124, 111);
}

.my-btn {
  border: 1px solid rgb(116, 124, 111);
  border-radius: 5px;
  background: transparent;
}

.my-btn:hover {
  background-color: rgb(116, 124, 111);
  color: white;
  transition: 0.5s;
}

.fs-big-title {
  font-size: 3rem;
}

.w-img-2 {
  width: 100%;
}

.text-inherit {
  color: inherit;
}

.blur {
  backdrop-filter: blur(5px);
}

.fs-small {
  font-size: 0.7rem;
}

@media (min-width: 992px) {
  .fs-big-title {
    font-size: 6rem !important;
  }
  .fs-title {
    font-size: 2.5rem !important;
  }

  .fs-text-4 {
    font-size: 1.5rem !important;
  }

  .w-img {
    width: 50% !important;
  }

  .w-img-2 {
    width: 50% !important;
  }
}
